/* eslint-disable no-underscore-dangle */
import { UtilsDom } from "@hotelchamp/common";

export let shadowRoot: ShadowRoot;

const shouldIgnoreDelay = localStorage.getItem("hc-ibe-ignore-delay");
const delayBoot = document.location.href.indexOf("aethos") !== -1 && (!shouldIgnoreDelay || shouldIgnoreDelay !== "true");

console?.log("[HC][IBE] Delay boot:", delayBoot);

import("./bootstrapper").then(({ bootstrapper }) => {
    runWhenDomReady(() => {
        setTimeout(() => (shadowRoot = bootstrapper()), delayBoot ? 1350 : 0);
    });
});

/**
 * List with detachers for DOM ready listeners
 * These will be detached once DOM is ready
 */
let domReadyDetachers: Array<() => void>;

/**
 * Helper to detect current DOM ready state
 * @returns
 */
const isDomReady = (): boolean => /loaded|complete|interactive/.test(document.readyState);

/**
 * Helper to run callback function once DOM is ready
 * @param cb
 */
const runWhenDomReady = (cb: Function) => {
    const onReady = () => {
        if (!isDomReady()) {
            return;
        }

        detachListeners(domReadyDetachers);

        cb();
    };

    if (!isDomReady()) {
        domReadyDetachers = [
            UtilsDom.on(window, "load", onReady),
            UtilsDom.on(document, "DOMContentLoaded", onReady),
            UtilsDom.on(document, "onreadystatechange", onReady),
        ];
    } else {
        cb();
    }
};

/**
 * Helper to detach listeners
 * @param detachers
 */
const detachListeners = (detachers: Array<() => void>) => {
    for (let i = 0; i < detachers.length; i++) {
        detachers[i]();
    }

    detachers = [];
};

/**
 * Global
 */
interface ISearchConfig {
    [key: string]: any;
}

declare global {
    interface Window {
        __HC__?: {
            ibe?: {
                search?: (config?: ISearchConfig) => void;
                pendingSearch?: Array<ISearchConfig | undefined>;
            };
        };
    }
}

/**
 * We would like to intercept window.__HC__.ibe.search calls when IBE is not ready yet.
 * This will store the search configuration and once the IBE is ready,
 * it will call the search function with the last stored configuration.
 */
if (!("__HC__" in window)) {
    window.__HC__ = {};

    if (!("ibe" in window?.__HC__)) {
        window.__HC__.ibe = {};

        if (!("search" in window?.__HC__?.ibe)) {
            window.__HC__.ibe.search = function(newConfig?: ISearchConfig) {
                // this should be part of customer IBE integration snippet. It will intercept call to the IBE when IBE is
                // not ready yet and store search configuration.
                // Once the IBE is ready, it will call the search function with the last stored configuration.
                // window.__HC__.ibe.pendingSearch = [newConfig];
                window!.__HC__!.ibe!.pendingSearch = [newConfig];
            };
        }
    }
}
